import Vue from 'vue';
import MapChart from '@lcp/map-chart';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faHandHoldingWater,
    faHouseFlood,
    faInfo, faTemperatureHot, faThunderstorm, faUsers, faWaterRise, faWind,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import router from './router';
import App from './App.vue';

library.add(faInfo, faUsers, faWind, faTemperatureHot, faHouseFlood, faThunderstorm, faWaterRise, faHandHoldingWater);
Vue.use(MapChart);
Vue.config.productionTip = false;
Vue.component('font-awesome-icon', FontAwesomeIcon);

new Vue({
    router,
    render: (h) => h(App),
}).$mount('#app');
