













































































import { Component, Vue, Watch } from 'vue-property-decorator';
import {
    MapOptions, MapChart, MapPos,
} from '@lcp/map-chart';
import countryMappings from '../services/countryMappings';
import RegionStats from '../models/regionStats';
import Office from '../models/office';

@Component
export default class Home extends Vue {
    hoveredRegion: string | null = '';

    hoveredArea: string | null = ''

    selectedType: keyof RegionStats = 'heat';

    selectedRegion: string | null = null;

    globePos: MapPos | null = null;

    get regionData (): { [key: string ]: RegionStats} {
        return {
            'Southern Europe': {
                wind: 1, heat: 2, flooding: 3, weather: 1, sea: 2, drought: 3,
            },
            'Northern and Central Europe': {
                wind: 1, heat: 1, flooding: 3, weather: 3, sea: 2, drought: 1,
            },
            'Middle East': {
                wind: 0, heat: 3, flooding: 0, weather: 2, sea: 1, drought: 3,
            },
            'Middle East (Western Asia)': {
                wind: 0, heat: 3, flooding: 0, weather: 2, sea: 1, drought: 3,
            },
            'North Asia': {
                wind: 0, heat: 1, flooding: 1, weather: 2, sea: 1, drought: 0,
            },
            'North and Central Asia': {
                wind: 0, heat: 1, flooding: 1, weather: 2, sea: 1, drought: 0,
            },
            'East Asia': {
                wind: 0, heat: 3, flooding: 2, weather: 0, sea: 1, drought: 2,
            },
            'South Asia': {
                wind: 0, heat: 3, flooding: 2, weather: 2, sea: 3, drought: 0,
            },
            'South East Asia': {
                wind: 0, heat: 3, flooding: 2, weather: 2, sea: 3, drought: 0,
            },
            Australasia: {
                wind: 0, heat: 3, flooding: 2, weather: 1, sea: 2, drought: 2,
            },
            Africa: {
                wind: 0, heat: 3, flooding: 2, weather: 0, sea: 3, drought: 3,
            },
            'South and Central America': {
                wind: 0, heat: 3, flooding: 0, weather: 3, sea: 2, drought: 2,
            },
            'American Southwest and Southeast': {
                wind: 0, heat: 2, flooding: 3, weather: 3, sea: 2, drought: 3,
            },
            'North America': {
                wind: 0, heat: 2, flooding: 3, weather: 3, sea: 2, drought: 2,
            },

        };
    }

    get selectedTypeDetails (): { id: string; icon: string; name: string } {
        return this.types.find((a) => a.id === this.selectedType)!;
    }

    get types (): Array<{ id: string; icon: string; name: string }> {
        return [
            { id: 'heat', icon: 'temperature-hot', name: 'Heat stress' },
            { id: 'wind', icon: 'wind', name: 'Wind' },
            { id: 'flooding', icon: 'house-flood', name: 'Flooding' },
            { id: 'weather', icon: 'thunderstorm', name: 'Extreme weather' },
            { id: 'sea', icon: 'water-rise', name: 'Sea levels rising' },
            { id: 'drought', icon: 'hand-holding-water', name: 'Drought' },
        ];
    }

    get overlayStyle (): unknown {
        if (!this.globePos) return {};
        return {
            transform: `translate(${this.globePos.x}px, ${this.globePos.y}px)`,
            width: `${this.globePos.width}px`,
            height: `${this.globePos.height}px`,
        };
    }

    selectOffice (office: Office): void {
        const area = (this.$refs.map as MapChart).getAreaByLatLong(office.latitude, office.longitude);
        const region = countryMappings[area];
        (this.$refs.map as MapChart).selectRegions([region]);
    }

    get hoveredRegionValue (): number| null {
        if (!this.hoveredRegion) return null;
        return this.regionData[this.hoveredRegion][this.selectedType];
    }

    get regions (): Array<string> {
        return [...new Set(Object.values(countryMappings))];
    }

    get offices (): Array<Office> {
        return [
            {
                latitude: 43.5769843,
                longitude: -79.7745306,
                radius: 4,
                strokeWidth: 0,
                fillColour: '#002f5f',
                name: 'Mississauga, Ontario',
            },
            {
                latitude: 40.8287482,
                longitude: -74.4209217,
                radius: 4,
                strokeWidth: 0,
                fillColour: '#002f5f',
                name: 'Whippany, NJ 07981',
            },
        ];
    }

    get mapData (): { [area: string]: number } {
        const mapData: { [area: string]: number } = {};
        Object.keys(countryMappings).forEach((area) => {
            mapData[area] = this.regionData[countryMappings[area]][this.selectedType];
        });
        return mapData;
    }

    get colours (): Array<string> {
        return ['#64a74a', '#f5e35a', '#f5b05a', '#ea633d'];
    }

    get currentValue (): number {
        if (!this.selectedRegion) {
            return Math.max(...Object.values(this.regionData).map((a) => a[this.selectedType]));
        }
        return this.regionData[this.selectedRegion][this.selectedType];
    }

    getRegionValueForType (region: string, type: keyof RegionStats): number {
        return this.regionData[region][type];
    }

    get mapOptions (): MapOptions {
        return {
            topoJsonSettings: {
                jsonPath: '/simple.json',
                featureCollectionName: 'collection',
                areaPropertyName: 'name',
            },
            mapProjection: {
                locale: 'globe',
            },
            extents: {
                extentsFunction: () => [0, 1, 2, 3],
            },
            interactive: {
                allowSelectArea: false,
                allowZoom: true,
                disabledAreas: ['Antarctica'],
            },
            display: {
                hiddenAreas: ['French Southern and Antarctic Lands'],
                gradient: true,
                defaultColour: 'rgb(222, 222, 222)',
                disabledColour: 'rgb(222, 222, 222)',
                globeFill: 'url(#mainGradient)',
                zoomLevel: 0.9,
                colourAnimationDuration: 0,
                zoomToSelected: false,
                gradientAmount: { hue: 10, saturation: 0, lightness: 10 },
                gradientEnd: 10,
                borderWidth: 0,
                borderColour: '#ccc',
                gradientStart: 0,
                areaOpacity: 0.95,
                colourRange: this.colours,
                selectedBorderColour: '#fff',
            },
            areaGroups: {
                getRegionForArea (country: string): string {
                    return countryMappings[country] || country;
                },
                hasRegions: true,
            },
            events: {
                regionHovered: (region: string | null) => {
                    this.hoveredRegion = region;
                },
                areaHovered: (area: string | null) => {
                    this.hoveredArea = area;
                },
                regionSelected: (region: Array<string>) => {
                    if (!region.length) {
                        this.selectedRegion = null;
                        return;
                    }
                    this.selectedRegion = region[0];
                },
                mapPositionChanged: (pos: MapPos) => { this.globePos = pos; },

            },
        };
    }

    @Watch('selectedRegion')
    selectedRegionChanged (): void {
        if (!this.selectedRegion) {
            (this.$refs.map as MapChart).deselect();
            return;
        }
        (this.$refs.map as MapChart).selectRegions([this.selectedRegion]);
    }
}
