const regions : {[key: string ]: string} = {
    Egypt: 'Africa',
    Libya: 'Africa',
    Morocco: 'Africa',
    Sudan: 'Africa',
    Tunisia: 'Africa',
    'Western Sahara': 'Africa',
    'British Indian Ocean Territory': 'Africa',
    Burundi: 'Africa',
    Comoros: 'Africa',
    Djibouti: 'Africa',
    Eritrea: 'Africa',
    Ethiopia: 'Africa',
    'French Southern Territories': 'Africa',
    Kenya: 'Africa',
    Madagascar: 'Africa',
    Malawi: 'Africa',
    Mauritius: 'Africa',
    Mayotte: 'Africa',
    Mozambique: 'Africa',
    Réunion: 'Africa',
    Rwanda: 'Africa',
    Seychelles: 'Africa',
    Somalia: 'Africa',
    Somaliland: 'Africa',
    'South Sudan': 'Africa',
    'United Republic of Tanzania': 'Africa',
    Uganda: 'Africa',
    Zambia: 'Africa',
    Zimbabwe: 'Africa',
    Angola: 'Africa',
    Cameroon: 'Africa',
    'Central African Republic': 'Africa',
    Chad: 'Africa',
    'Republic of the Congo': 'Africa',
    'Republic of Congo': 'Africa',
    'Democratic Republic of the Congo': 'Africa',
    'Equatorial Guinea': 'Africa',
    Gabon: 'Africa',
    'Sao Tome and Principe': 'Africa',
    Botswana: 'Africa',
    Eswatini: 'Africa',
    Lesotho: 'Africa',
    Namibia: 'Africa',
    'South Africa': 'Africa',
    Benin: 'Africa',
    'Burkina Faso': 'Africa',
    'Cape Verde': 'Africa',
    'Ivory Coast': 'Africa',
    Gambia: 'Africa',
    Ghana: 'Africa',
    Guinea: 'Africa',
    'Guinea Bissau': 'Africa',
    Liberia: 'Africa',
    Algeria: 'Africa',
    Mali: 'Africa',
    Mauritania: 'Africa',
    Niger: 'Africa',
    Nigeria: 'Africa',
    'Saint Helena, Ascension and Tristan da Cunha': 'Africa',
    Senegal: 'Africa',
    'Sierra Leone': 'Africa',
    Togo: 'Africa',
    Anguilla: 'Africa',
    'Antigua and Barbuda': 'American Southwest and Southeast',
    Aruba: 'American Southwest and Southeast',
    'The Bahamas': 'American Southwest and Southeast',
    Barbados: 'American Southwest and Southeast',
    'Bonaire, Sint Eustatius and Saba': 'American Southwest and Southeast',
    'Cayman Islands': 'American Southwest and Southeast',
    Cuba: 'American Southwest and Southeast',
    Curaçao: 'American Southwest and Southeast',
    Dominica: 'American Southwest and Southeast',
    'Dominican Republic': 'American Southwest and Southeast',
    Grenada: 'American Southwest and Southeast',
    Guadeloupe: 'American Southwest and Southeast',
    Haiti: 'American Southwest and Southeast',
    Jamaica: 'American Southwest and Southeast',
    Martinique: 'American Southwest and Southeast',
    Montserrat: 'American Southwest and Southeast',
    'Puerto Rico': 'American Southwest and Southeast',
    'Saint Barthélemy': 'American Southwest and Southeast',
    'Saint Kitts and Nevis': 'American Southwest and Southeast',
    'Saint Lucia': 'American Southwest and Southeast',
    'Saint Martin (French part)': 'American Southwest and Southeast',
    'Saint Vincent and the Grenadines': 'American Southwest and Southeast',
    'Sint Maarten (Dutch part)': 'American Southwest and Southeast',
    'Trinidad and Tobago': 'American Southwest and Southeast',
    'Turks and Caicos Islands': 'American Southwest and Southeast',
    'Virgin Islands (British)': 'American Southwest and Southeast',
    'Virgin Islands (U.S.)': 'American Southwest and Southeast',
    Belize: 'American Southwest and Southeast',
    'Costa Rica': 'South and Central America',
    'El Salvador': 'South and Central America',
    Guatemala: 'South and Central America',
    Honduras: 'South and Central America',
    Mexico: 'South and Central America',
    Nicaragua: 'South and Central America',
    Panama: 'South and Central America',
    Bermuda: 'South and Central America',
    Canada: 'North America',
    Greenland: 'North America',
    'Saint Pierre and Miquelon': 'North America',
    'United States of America': 'North America',
    Argentina: 'South and Central America',
    Bolivia: 'South and Central America',
    'Bouvet Island': 'South and Central America',
    Brazil: 'South and Central America',
    Chile: 'South and Central America',
    Colombia: 'South and Central America',
    Ecuador: 'South and Central America',
    'Falkland Islands': 'South and Central America',
    'French Guiana': 'South and Central America',
    Guyana: 'South and Central America',
    Paraguay: 'South and Central America',
    Peru: 'South and Central America',
    'South Georgia and South Sandwich Islands': 'South and Central America',
    Suriname: 'South and Central America',
    Uruguay: 'South and Central America',
    Venezuela: 'South and Central America',
    // Antarctica: 'South and Central America',
    Kazakhstan: 'North and Central Asia',
    'Baikonur Cosmodrome': 'North and Central Asia',
    Kyrgyzstan: 'North and Central Asia',
    Tajikistan: 'North and Central Asia',
    Turkmenistan: 'North and Central Asia',
    Uzbekistan: 'North and Central Asia',
    China: 'North and Central Asia',
    'Siachen Glacier': 'North and Central Asia',
    Taiwan: 'North and Central Asia',
    'Hong Kong': 'East Asia',
    Japan: 'East Asia',
    'North Korea': 'East Asia',
    'South Korea': 'East Asia',
    Macao: 'East Asia',
    Mongolia: 'East Asia',
    Brunei: 'South East Asia',
    Cambodia: 'South East Asia',
    Indonesia: 'South East Asia',
    Laos: 'South East Asia',
    Malaysia: 'South East Asia',
    Myanmar: 'South East Asia',
    Philippines: 'South East Asia',
    Singapore: 'South East Asia',
    Thailand: 'South East Asia',
    'East Timor': 'South East Asia',
    Vietnam: 'South East Asia',
    Afghanistan: 'South Asia',
    Bangladesh: 'South Asia',
    Bhutan: 'South Asia',
    India: 'South Asia',
    Iran: 'South Asia',
    Maldives: 'South Asia',
    Nepal: 'South Asia',
    Pakistan: 'South Asia',
    'Sri Lanka': 'South Asia',
    Armenia: 'South Asia',
    Azerbaijan: 'Middle East (Western Asia)',
    Bahrain: 'Middle East (Western Asia)',
    Cyprus: 'Middle East (Western Asia)',
    'Northern Cyprus': 'Middle East (Western Asia)',
    Georgia: 'Middle East (Western Asia)',
    Iraq: 'Middle East (Western Asia)',
    Israel: 'Middle East (Western Asia)',
    Jordan: 'Middle East (Western Asia)',
    Kuwait: 'Middle East (Western Asia)',
    Lebanon: 'Middle East (Western Asia)',
    Oman: 'Middle East (Western Asia)',
    Palestine: 'Middle East (Western Asia)',
    Qatar: 'Middle East (Western Asia)',
    'Saudi Arabia': 'Middle East (Western Asia)',
    Syria: 'Middle East (Western Asia)',
    Turkey: 'Middle East (Western Asia)',
    'United Arab Emirates': 'Middle East (Western Asia)',
    Yemen: 'Middle East (Western Asia)',
    Belarus: 'Northern and Central Europe',
    Bulgaria: 'Northern and Central Europe',
    Czechia: 'Northern and Central Europe',
    Hungary: 'Northern and Central Europe',
    Moldova: 'Northern and Central Europe',
    Poland: 'Northern and Central Europe',
    Romania: 'Northern and Central Europe',
    Russia: 'Northern and Central Europe',
    Slovakia: 'Northern and Central Europe',
    Ukraine: 'Northern and Central Europe',
    'Åland Islands': 'Northern and Central Europe',
    Guernsey: 'Northern and Central Europe',
    Jersey: 'Northern and Central Europe',
    Sark: 'Northern and Central Europe',
    Denmark: 'Northern and Central Europe',
    Estonia: 'Northern and Central Europe',
    'Faroe Islands': 'Northern and Central Europe',
    Finland: 'Northern and Central Europe',
    Iceland: 'Northern and Central Europe',
    Ireland: 'Northern and Central Europe',
    'Isle of Man': 'Northern and Central Europe',
    Latvia: 'Northern and Central Europe',
    Lithuania: 'Northern and Central Europe',
    Norway: 'Northern and Central Europe',
    'Svalbard and Jan Mayen': 'Northern and Central Europe',
    Sweden: 'Northern and Central Europe',
    'United Kingdom': 'Northern and Central Europe',
    Albania: 'Southern Europe',
    Andorra: 'Southern Europe',
    'Bosnia and Herzegovina': 'Southern Europe',
    Croatia: 'Southern Europe',
    Gibraltar: 'Southern Europe',
    Greece: 'Southern Europe',
    'Holy See [Vatican City]': 'Southern Europe',
    Italy: 'Southern Europe',
    Malta: 'Southern Europe',
    Montenegro: 'Southern Europe',
    Macedonia: 'Southern Europe',
    Portugal: 'Southern Europe',
    'San Marino': 'Southern Europe',
    'Republic of Serbia': 'Southern Europe',
    Slovenia: 'Southern Europe',
    Spain: 'Southern Europe',
    Austria: 'Southern Europe',
    Belgium: 'Northern and Central Europe',
    France: 'Northern and Central Europe',
    Germany: 'Northern and Central Europe',
    Liechtenstein: 'Northern and Central Europe',
    Luxembourg: 'Northern and Central Europe',
    Monaco: 'Northern and Central Europe',
    Netherlands: 'Northern and Central Europe',
    Switzerland: 'Northern and Central Europe',
    Kosovo: 'Southern Europe',
    Australia: 'Australasia',
    'Christmas Island': 'Australasia',
    'Cocos (Keeling) Islands': 'Australasia',
    'Heard Island and McDonald Islands': 'Australasia',
    'New Zealand': 'Australasia',
    'Norfolk Island': 'Australasia',
    Fiji: 'Australasia',
    'New Caledonia': 'Australasia',
    'Papua New Guinea': 'Australasia',
    'Solomon Islands': 'Australasia',
    Vanuatu: 'Australasia',
    // 'French Southern and Antarctic Lands': 'Australasia',
    Guam: 'Australasia',
    Kiribati: 'Australasia',
    'Marshall Islands': 'Australasia',
    'Micronesia (Federated States of)': 'Australasia',
    Nauru: 'Australasia',
    'Northern Mariana Islands': 'Australasia',
    Palau: 'Australasia',
    'United States Minor Outlying Islands': 'Australasia',
    'American Samoa': 'Australasia',
    'Cook Islands': 'Australasia',
    // 'French Polynesia': 'Australasia',
    Niue: 'Australasia',
    Pitcairn: 'Australasia',
    // Samoa: 'Australasia',
    Tokelau: 'Australasia',
    Tonga: 'Australasia',
    Tuvalu: 'Australasia',
    'Wallis and Futuna': 'Australasia',
};

export default regions;
